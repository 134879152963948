@import '_variables.scss';


.alert-quote.alert-danger {
  margin-top: 1rem;
}

.alert-danger {
  background-color: #FFDBDB;
  border-color: $red;
  color: $red;
}

.alert-primary {
  background-color: #DCF0F9;
  border-color: $blue-secondary;
  color: $blue-secondary;
}

.alert-danger,
.alert-primary {
  font-size: 14px;
}
