@import "../../scss/_variables.scss";

.footer {
  background-color: $gray-900;
  margin-top: 30px;
  padding: 62px 0 32px 0;
  font-size: 14px;
  color: $white;
}

.footer .container {
  max-width: 1000px;
}

.footer a {
  color: $white;
}

.footer a:hover {
  text-decoration: underline !important;
}

.footer .links {
  margin-bottom: 35px;
}

.footer h6 {
  font-size: 16px;
  font-weight: 700;
}

.footer ul {
  margin: 0px;
  padding: 0px;
  list-style-type: none;
}

.footer ul > li {
  padding: 5px 0;
}

.footer .copyright {
  font-size: 16px;
  color: #bebfc2;
}

.footer .attribution {
  font-size: 12px;
  font-weight: 600;
}

.footer .attribution a {
  color: $white;
  text-decoration: underline;
}

@media (max-width: 767px) {
  .footer {
    margin-top: 100px;
  }

  .footer .links {
    width: 100%;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
  }

  .footer .attribution,
  .footer .copyright {
    font-size: 14px;
    color: $white;
  }
}
