label {
  width: 100%;
  font-weight: $input-font-weight;
  font-size: 14px;
}

.form-control:focus {
  font-size: $input-focus-font-size;
}


@media (max-width: 768px) {
  .form-group label {
    font-size: 16px;
  }

  .form-group {
    margin-bottom: $form-group-margin-bottom-sm;
  }

  .form-control {
    font-size: $input-font-size-sm;
    min-height: $input-height-sm;
  }

  .form-control:focus {
    font-size: $input-font-size * 1.125;
  }
}
