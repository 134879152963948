@import "../../scss/_variables.scss";

.navbar {
  background-color: $white;
  border-bottom: 1px solid $gray-400;
  z-index: 3000;
}

.nav-item {
  margin: auto 22.5px;
}

.nav-item:last-of-type {
  margin-right: 45px;
}

.nav-item,
.nav-link {
  color: $gray-700 !important;
  padding: 0 !important;
}

.nav-link:hover {
  color: $gray-600 !important;
}

.dropdown-menu {
  padding: 11px 0;
}

.dropdown-menu.show {
  position: absolute;
  will-change: transform;
  top: 0px;
  left: 0px;
  transform: translate3d(0px, 20px, 0px);
}

.dropdown-item {
  padding: 4px 15px;
}

.nav-item,
.dropdown-item {
  font-size: 14px;
  font-weight: 600;
}

.phone {
  font-size: 14px;
  font-weight: 600;
  margin-right: 30px;
}

.phone i {
  font-size: 16px;
  margin-right: 8px;
  /* color: $green; */
}

@media screen and (max-width: 767px) {
  .navbar {
    box-shadow: $box-shadow;
    border-bottom: none;
    background-color: $primary;
    padding: 8px 30px;
  }

  .navbar-collapse {
    background-color: $primary;
    box-shadow: inset 0 3px 6px rgba(0, 0, 0, 0.3);
    position: absolute;
    top: 59.25px;
    left: 0;
    right: 0;
    z-index: 1;
  }

  .navbar-nav {
    padding: 40px 54px;
    overflow-x: scroll;
  }

  .logo {
    color: $white;
  }

  .nav-item,
  .nav-link,
  .dropdown-item {
    font-size: 18px;
    color: $white !important;
    padding: 0 !important;
    margin: 7.5px 0;
  }

  .nav-link:hover {
    color: $white !important;
  }

  .nav-header {
    font-size: 20px;
    font-weight: 700;
  }

  .nav-header:first-child {
    margin-bottom: 13px;
  }

  .nav-header:last-child {
    margin-top: 13px;
  }

  .navbar-toggler-icon {
    /* Bars and Close icons are different widths so set to prevent movement of
    other navbar items */
    width: 28px;
  }

  .navbar-toggler-icon:before,
  .phone i {
    font-size: 32px;
    color: $white;
  }

  .dropdown-menu {
    position: static !important;
    border-width: 0;
    padding: 0;
    margin-left: 10px;
    background: none;
  }

  .nav-link:after {
    transition: transform 0.3s ease-in-out;
  }

  .nav-link[aria-expanded="true"]:after {
    transform: rotate(180deg);
  }

  .dropdown-item {
    display: initial;
  }
}
