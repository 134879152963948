// Flex variation
//
// Custom styles for additional flex alignment options with added IE support.
// https://stackoverflow.com/questions/37905749/flexbox-not-working-in-internet-explorer-11

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .flex#{$infix}-row            { -ms-flex-direction: row !important; flex-direction: row !important; }
    .flex#{$infix}-column         { -ms-flex-direction: column !important; flex-direction: column !important; }
    .flex#{$infix}-row-reverse    { -ms-flex-direction: row-reverse !important; flex-direction: row-reverse !important; }
    .flex#{$infix}-column-reverse { -ms-flex-direction: column-reverse !important; flex-direction: column-reverse !important; }

    .flex#{$infix}-wrap         { -ms-flex-wrap: wrap !important; flex-wrap: wrap !important; }
    .flex#{$infix}-nowrap       { -ms-flex-wrap: nowrap !important; flex-wrap: nowrap !important; }
    .flex#{$infix}-wrap-reverse { -ms-flex-wrap: wrap-reverse !important; flex-wrap: wrap-reverse !important; }
    .flex#{$infix}-fill         { -ms-flex: 1 1 auto !important; flex: 1 1 auto !important; }
    .flex#{$infix}-grow-0       { -ms-flex-grow: 0 !important; flex-grow: 0 !important; }
    .flex#{$infix}-grow-1       { -ms-flex-grow: 1 !important; flex-grow: 1 !important; }
    .flex#{$infix}-shrink-0     { -ms-flex-shrink: 0 !important; flex-shrink: 0 !important; }
    .flex#{$infix}-shrink-1     { -ms-flex-shrink: 1 !important; flex-shrink: 1 !important; }

    .justify-content#{$infix}-start   { -ms-justify-content: flex-start !important; justify-content: flex-start !important; }
    .justify-content#{$infix}-end     { -ms-justify-content: flex-end !important; justify-content: flex-end !important; }
    .justify-content#{$infix}-center  { -ms-justify-content: center !important; justify-content: center !important; }
    .justify-content#{$infix}-between { -ms-justify-content: space-between !important; justify-content: space-between !important; }
    .justify-content#{$infix}-around  { -ms-justify-content: space-around !important; justify-content: space-around !important; }

    .align-items#{$infix}-start    { -ms-align-items: flex-start !important; align-items: flex-start !important; }
    .align-items#{$infix}-end      { -ms-align-items: flex-end !important; align-items: flex-end !important; }
    .align-items#{$infix}-center   { -ms-align-items: center !important; align-items: center !important; }
    .align-items#{$infix}-baseline { -ms-align-items: baseline !important; align-items: baseline !important; }
    .align-items#{$infix}-stretch  { -ms-align-items: stretch !important; align-items: stretch !important; }

    .align-content#{$infix}-start   { -ms-align-content: flex-start !important; align-content: flex-start !important; }
    .align-content#{$infix}-end     { -ms-align-content: flex-end !important; align-content: flex-end !important; }
    .align-content#{$infix}-center  { -ms-align-content: center !important; align-content: center !important; }
    .align-content#{$infix}-between { -ms-align-content: space-between !important; align-content: space-between !important; }
    .align-content#{$infix}-around  { -ms-align-content: space-around !important; align-content: space-around !important; }
    .align-content#{$infix}-stretch { -ms-align-content: stretch !important; align-content: stretch !important; }

    .align-self#{$infix}-auto     { -ms-align-self: auto !important; align-self: auto !important; }
    .align-self#{$infix}-start    { -ms-align-self: flex-start !important;  align-self: flex-start !important; }
    .align-self#{$infix}-end      { -ms-align-self: flex-end !important; align-self: flex-end !important; }
    .align-self#{$infix}-center   { -ms-align-self: center !important; align-self: center !important; }
    .align-self#{$infix}-baseline { -ms-align-self: baseline !important; align-self: baseline !important; }
    .align-self#{$infix}-stretch  { -ms-align-self: stretch !important; align-self: stretch !important; }
  }
}
