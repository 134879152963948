@import "buttons";
@import "forms";
@import "buttons";
@import "alert";
@import "input-group";
@import "mixins/flex";
@import "mixins/caret";

.page-container {
  min-height: 100vh;
}

.font-bold {
  font-weight: $font-weight-bold;
}

.font-bold-extra,
h2 {
  font-weight: 800;
}

.font-bold-semi {
  font-weight: 600;
}

h1 {
  font-size: 55px;
}

h2 {
  font-size: 40px;
  margin: 0;
}

h3 {
  font-size: 32px;
  font-weight: 700;
}

h4 {
  font-size: 24px;
  font-weight: 700;
}

h5 {
  font-size: 20px;
  font-weight: 700;
}

h6 {
  font-size: 18px;
  font-weight: 400;
}

@media (max-width: 767px) {
  .container {
    padding-right: 30px;
    padding-left: 30px;
  }
}

[type="checkbox"]:checked,
[type="checkbox"]:not(:checked),
[type="radio"]:checked,
[type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}

[type="checkbox"]:checked + label,
[type="checkbox"]:not(:checked) + label,
[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #666;
}

[type="checkbox"]:checked + label:before,
[type="checkbox"]:not(:checked) + label:before,
[type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 2px solid $purple-secondary;
  background: $white;
}

[type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
  border-radius: 100%;
}

[type="checkbox"]:checked + label:before,
[type="checkbox"]:not(:checked) + label:before {
  border-radius: 5px;
}

[type="checkbox"]:not(:checked) + label:before,
[type="radio"]:not(:checked) + label:before {
  border-color: #ccc;
}

[type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
  content: "";
  width: 10px;
  height: 10px;
  background: $purple-secondary;
  position: absolute;
  top: 4px;
  left: 4px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
[type="checkbox"]:checked + label:after,
[type="checkbox"]:not(:checked) + label:after {
  position: absolute;
  content: "\f00c";
  width: 10px;
  height: 10px;
  left: 3px;
  top: 0;
  color: $purple-secondary;
  font-size: 0.9em;
  font-family: "Font Awesome 5 Free";
}

[type="radio"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

[type="radio"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

/* forces the append addon to align right */
.text-right.basic.input-group-append > .input-group-text {
  margin: auto;
  margin-right: 0;
}

.basic.input-group-prepend > .input-group-text,
.basic.input-group-append > .input-group-text {
  background: none;
  border: none;
  padding: 0;
}

input[type="range"] {
  -webkit-appearance: none;
  border: none;
  background: transparent;
  width: 100%;
}

input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 6px;
  background: $gray-200;
  border-radius: 6px;
  margin: auto 5px;
}

input[type="range"]::-moz-range-track {
  width: 100%;
  height: 6px;
  background: $gray-200;
  border-radius: 6px;
  margin: auto 5px;
}

input[type="range"]::-ms-track {
  width: 100%;
  height: 6px;
  background: $gray-200;
  border-radius: 6px;
  margin: auto 5px;
}

input[type="range"]::-moz-range-thumb {
  box-shadow: 0 0 0 3px rgba(77, 0, 128, 0.5);
  border: none;
  height: 13px;
  width: 13px;
  border-radius: 13px;
  background: $primary;
  cursor: pointer;
  margin-top: -3.5px;
}
input[type="range"]::-ms-thumb {
  box-shadow: 0 0 0 3px rgba(77, 0, 128, 0.5);
  border: none;
  height: 13px;
  width: 13px;
  border-radius: 13px;
  background: $primary;
  cursor: pointer;
  margin-top: -3.5px;
}
input[type="range"]::-webkit-slider-thumb {
  box-shadow: 0 0 0 3px rgba(77, 0, 128, 0.5);
  border: none;
  height: 13px;
  width: 13px;
  border-radius: 13px;
  background: $primary;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -3.5px;
}

.form-check-inline .form-check-label {
  margin-right: 60px;
}

.form-control:not([type="range"]) {
  background-color: $gray-100;
  border: 1px solid $gray-400;
  border-radius: 0;
  appearance: none;
}

.form-group {
  margin-bottom: 12px;
}

.form-group label {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 5px;
}

.form-control {
  font-size: 14px;
  min-height: 40px;
  padding: 0.375rem 25px;
}

.input-group-prepend + .form-control {
  padding-left: 10px;
}

.form-group.show-tooltip .input-group-text,
.form-group.show-tooltip .form-control:not([type="range"]) {
  border-color: $blue-secondary;
}

.form-group.show-error .input-group-text,
.form-group.show-error .form-control:not([type="range"]) {
  border-color: $red;
}

.form-group.show-error
  .input-group-prepend
  + .form-control:not([type="range"]) {
  border-left-color: $gray-400;
}

.form-control:focus {
  font-size: 15px;
  color: $gray-700;
}

@media (max-width: 768px) {
  .form-group label {
    font-size: 16px;
  }

  .form-group {
    margin-bottom: 20px;
  }

  .form-control {
    font-size: 16px;
    min-height: 45px;
  }

  .form-control:focus {
    font-size: 18px;
  }
}

select {
  display: block;
  padding-right: 32px !important;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzMjAgNTEyIj48cGF0aCBmaWxsPSIjM0IzQjNCIiBkPSJNMzEuMyAxOTJoMjU3LjNjMTcuOCAwIDI2LjcgMjEuNSAxNC4xIDM0LjFMMTc0LjEgMzU0LjhjLTcuOCA3LjgtMjAuNSA3LjgtMjguMyAwTDE3LjIgMjI2LjFDNC42IDIxMy41IDEzLjUgMTkyIDMxLjMgMTkyeiI+PC9wYXRoPjwvc3ZnPg==");
  background-repeat: no-repeat, repeat;
  background-position: right 25px top 50%, 0 0;
  background-size: 0.65em auto, 100%;
}

label {
  font-weight: 600;
}

.container {
  padding-left: 30px;
  padding-right: 30px;
}

@media screen and (max-width: 767px) {
  .btn-primary,
  .btn-primary:hover,
  a.btn-primary:not([href]):not([tabindex]) {
    padding-left: 25px;
    padding-right: 25px;
  }
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: $gray-200;
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.5em;
  vertical-align: 0;
  border: none;
  content: "\f107";
  font-family: "Font Awesome 5 Free";
  font-size: 14px;
  font-weight: 800;
}

.curved-jumbotron {
  border-radius: 0;
  padding: 40px;
  background: none !important;
  position: relative;
  overflow-x: hidden;
  width: 100%;
}

.curved-jumbotron:after {
  box-shadow: $box-shadow;
  border-radius: 0 0 100% 100%;
  position: absolute;
  background: $primary;
  right: -20%;
  left: -20%;
  top: 0;
  content: "";
  height: 580px;
  z-index: -1;
}

@media (max-width: 767px) {
  .curved-jumbotron,
  .curved-jumbotron .content {
    padding: 0px;
    max-width: 100%;
  }

  .curved-jumbotron:after {
    display: none;
  }
}

.curved-jumbotron .text {
  margin-right: 200px;
}

.curved-jumbotron h1,
.curved-jumbotron h3 {
  color: var(--colour-white);
}

.curved-jumbotron h1 {
  font-weight: 800;
}

.curved-jumbotron h3 {
  font-size: 24px;
  font-weight: 500;
}

.bg-progress-bar {
  background-color: $green;
  border-radius: 0.25rem;
}
