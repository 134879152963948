@import '_variables.scss';


.btn {
  transition: box-shadow 0.3s ease-in-out;
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show>.btn-primary.dropdown-toggle {
  color: $white;
  background-color: $green;
}

.btn-primary,
.btn-primary:hover,
a.btn-primary:not([href]):not([tabindex]){
  color: $white;
  background-color: $green;
  font-weight: $font-weight-semibold;
  font-size: 16px;
  text-align: center;
  border: none;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  padding: 10px 20px;
  margin-bottom: 15px;
}

.btn:not(.btn-link):hover{
  box-shadow: $box-shadow;
}


.btn:not(.btn-link):hover{
  box-shadow: $box-shadow;
}

.btn-unstyled:focus,
.btn-unstyled:active,
.btn-unstyled {
  background: none;
  border: none;
  outline: none;
}

.btn-link,
.btn-link:hover {
  color: $purple-text;
}

.btn-light {
    color: $black;
    background-color: $white;
    border: 1px solid $white;
}

.btn-basic {
  background: transparent;
  color: $gray-700;
}

.btn-basic:hover {
  box-shadow: none;
}

.btn.btn-default {
    border: solid 1px $primary;
    color: $primary;
    padding: 10px;
    margin-bottom: 15px;
}

.btn.btn-default:active {
  box-shadow: none;
  background-color: #DACBE5;
}

.btn-secondary {
  background-color: transparent;
  color: $gray-700;
  border: none;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  padding: 10px 20px;
}

.btn-lg,
.btn-lg:hover {
  font-size: 18px;
}


@media screen and (max-width: 767px){
    .btn-primary,
    .btn-primary:hover,
    a.btn-primary:not([href]):not([tabindex]){
        padding-left: 25px;
        padding-right: 25px;
    }

}
